import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MassAttackAudio from './audios/mass-attack.mp3'
import MassAttackStartsInAudio from './audios/mass_attack_starts_in.mp3'
import FiveAudio from './audios/five.mp3'
import FourAudio from './audios/four.mp3'
import ThreeAudio from './audios/three.mp3'
import TwoAudio from './audios/two.mp3'
import OneAudio from './audios/one.mp3'
import { io } from 'socket.io-client'
import { CUSTOM_ITEM_ID, MASS_ATTACK_WAITING_TIME, MAX_CUSTOM_ITEMS_QUANTITY, WEB_SOCKET_ID } from './Utils'
import i18n from './i18n/i18n'
import { LinearProgress, Stack, Typography } from '@mui/material'
import Logo from './images/icons/logo-with-border.png'
import AppNameImage from './images/icons/viewer-attack-text.png'
import { styled } from '@mui/material/styles'

UnityWebPage.propTypes = {
    broadcasterProp: PropTypes.object.isRequired
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 10,
    border: '3px solid white',
    width: 200
}))

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

export function UnityWebPage({ broadcasterProp }) {

    const [broadcaster, setBroadcaster] = useState(broadcasterProp)
    const [isMassAttack, setIsMassAttack] = useState(false)
    const [massAttackTimer, setMassAttackTimer] = useState(100)
    const [massAttackWaitingTimer, setMassAttackWaitingTimer] = useState(-1)
    const [showBrowserSourceCorners, setShowBrowserSourceCorners] = useState(false)
    const [unityError, setUnityError] = useState(null)

    const [pageInBackground, setPageInBackground] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)
    const [loadingProgression, setLoadingProgression] = useState(0)

    const unityInstance = useRef(null)
    const massAttackCountdownAudios = useRef([FiveAudio, FourAudio, ThreeAudio, TwoAudio, OneAudio, MassAttackAudio])
    const unityErrorCount = useRef(0)

    const handleVisibilityChange = () => {
        if (document.hidden) {
            setPageInBackground(true)
        } else {
            setPageInBackground(false)
        }
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange, false)

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    useEffect(() => {
        if (!unityError) {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                // Mobile device style: fill the whole browser client area with the game canvas:
                const meta = document.createElement('meta')
                meta.name = 'viewport'
                meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes'
                document.getElementsByTagName('head')[0].appendChild(meta)

                const canvas = document.querySelector('#unity-canvas')
                canvas.style.width = '100%'
                canvas.style.height = '100%'
                canvas.style.position = 'fixed'

                document.body.style.textAlign = 'left'
            }

            let deviceType = 'Desktop'
            const c = document.createElement('canvas')
            const gl = c.getContext('webgl')
            const gl2 = c.getContext('webgl2')
            if ((gl && gl.getExtension('WEBGL_compressed_texture_astc')) || (gl2 &&
                gl2.getExtension('WEBGL_compressed_texture_astc'))) {
                deviceType = 'Mobile'
            }

            window.createUnityInstance(document.querySelector('#unity-canvas'), {
                dataUrl: `https://viewerattack.fra1.digitaloceanspaces.com/unity/v17/${deviceType}/Build/build.data`,
                frameworkUrl: 'https://viewerattack.fra1.digitaloceanspaces.com/unity/v17/Desktop/Build/build.framework.js',
                codeUrl: 'https://viewerattack.fra1.digitaloceanspaces.com/unity/v17/Desktop/Build/build.wasm',
                streamingAssetsUrl: `https://viewerattack.fra1.digitaloceanspaces.com/unity/v17/${deviceType}/StreamingAssets`,
                companyName: 'Dried Beans',
                productName: 'Viewer Attack',
                productVersion: '1.2'
                // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
                // devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
            }, (progress) => {
                setLoadingProgression(progress)
            }).then((_unityInstance) => {
                unityInstance.current = _unityInstance
                setIsLoaded(true)
            }).catch((error) => {
                console.error('Unity error: ', error)
                setUnityError(error)
                unityErrorCount.current++
            })
        }
    }, [unityError])

    //Permette di gestire l'errore di unity e riprovare a caricare il gioco per almeno 5 volte
    useEffect(() => {
        let timeoutId
        if (unityError && unityErrorCount.current < 5) {
            timeoutId = setTimeout(() => {
                setUnityError(null)
            }, 30000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [unityError])

    const spawnItem = useCallback((attackItem) => {
        unityInstance.current.SendMessage('MainCamera', 'SpawnItem', JSON.stringify(attackItem))
    }, [])

    //Riproduce un audio
    const playAudio = useCallback((audioPath, volume) => {
        //console.log(audioPath)
        try {
            const audio = new Audio(audioPath)
            audio.load()
            audio.volume = volume / 100
            audio.loop = false
            audio.play().catch((e) => {
                console.warn(e)
            })
        } catch (e) {
            console.warn(e)
        }
    }, [])

    //Calcola il valore (da 0 a 100) del timer del mass attack
    const calculateMassAttackTimer = useCallback(() => {
        if (!broadcaster) {
            return 0
        }
        const timePassed = new Date().getTime() - broadcaster.mass_attack.start_date - MASS_ATTACK_WAITING_TIME
        const duration = broadcaster.settings.mass_attack.duration * 1000
        if (timePassed >= duration) {
            return 0
        } else {
            return 100 - (100 * timePassed / duration)
        }
    }, [broadcaster])

    //Calcola e imposta il mass attack a false quando termina
    useEffect(() => {
        const massAttackTimeoutIds = []
        if (broadcaster && broadcaster.settings.mass_attack.enabled) {
            const timePassed = new Date().getTime() - broadcaster.mass_attack.start_date
            const duration = broadcaster.settings.mass_attack.duration * 1000 + MASS_ATTACK_WAITING_TIME //Aggiungo i 5 secondi di attesa del Mass Attack
            if (timePassed >= 0 && timePassed <= duration) {
                //Gestisce il countdown prima del mass attack
                if (timePassed < 1500) {
                    setMassAttackWaitingTimer(6)
                    playAudio(MassAttackStartsInAudio, broadcaster.settings.advanced?.mass_attack_voice_volume ?? 100)
                }
                for (let i = 0; i < massAttackCountdownAudios.current.length; i++) {
                    if (timePassed < (2500 + i * 1000)) {
                        massAttackTimeoutIds.push(setTimeout(() => {
                            setMassAttackWaitingTimer(5 - i)
                            playAudio(massAttackCountdownAudios.current[i], broadcaster.settings.advanced?.mass_attack_voice_volume ?? 100)
                        }, (2500 + i * 1000) - timePassed))
                    }
                }

                const waitingTime = (MASS_ATTACK_WAITING_TIME - timePassed > 0) ? (MASS_ATTACK_WAITING_TIME - timePassed) : 0
                massAttackTimeoutIds.push(setTimeout(() => {
                    setMassAttackWaitingTimer(-1)
                    setIsMassAttack(true)
                    massAttackTimeoutIds.push(setTimeout(() => {
                        setIsMassAttack(false)
                    }, duration - timePassed - waitingTime))
                }, waitingTime))
            }
        }

        return () => {
            setMassAttackWaitingTimer(-1)
            setIsMassAttack(false)
            for (const timeoutId of massAttackTimeoutIds) {
                clearTimeout(timeoutId)
            }
        }
    }, [broadcaster, playAudio])

    //Aggiorna il valore del timer del mass attack
    useEffect(() => {
        let massAttackIntervalId
        if (isMassAttack) {
            massAttackIntervalId = setInterval(() => {
                setMassAttackTimer(calculateMassAttackTimer)
            }, 100)
        }
        return () => {
            if (massAttackIntervalId) {
                clearInterval(massAttackIntervalId)
            }
        }
    }, [isMassAttack, calculateMassAttackTimer])

    //Reimposta il timer del mass attack a 100 quando finisce il mass attack
    useEffect(() => {
        if (!isMassAttack) {
            setMassAttackTimer(100)
        }
    }, [isMassAttack])

    //Invia size a unity quando viene aggiornata
    useEffect(() => {
        if (isLoaded) {
            unityInstance.current.SendMessage('MainCamera', 'UpdateItemsSize', broadcaster.settings.items.size)

            const urlFileList = {
                imageUrls: [],
                audioUrls: []
            }
            broadcaster.emotes?.forEach(emote => {
                urlFileList.imageUrls.push(emote.image_url)
            })
            for (let i = 0; i < MAX_CUSTOM_ITEMS_QUANTITY; i++) {
                const itemId = CUSTOM_ITEM_ID + '#' + i
                const customItem = broadcaster.custom_items[itemId]
                if (customItem?.image_url) {
                    urlFileList.imageUrls.push(customItem.image_url)
                }
                if (customItem?.audio_url) {
                    urlFileList.audioUrls.push(customItem.audio_url)
                }
            }
            unityInstance.current.SendMessage('MainCamera', 'LoadFileUrls', JSON.stringify(urlFileList))
        }
    }, [broadcaster.settings.items, broadcaster.emotes, broadcaster.custom_items, isLoaded])

    //Mostra dei bordi neri negli angoli dello schermo per 30 secondi
    useEffect(() => {
        let timeoutId
        if (showBrowserSourceCorners) {
            timeoutId = setTimeout(() => {
                setShowBrowserSourceCorners(false)
            }, 30000)
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [showBrowserSourceCorners])

    useEffect(() => {
        let socket
        if (isLoaded && !pageInBackground) {
            console.log('Socket connection')
            socket = io(WEBSOCKET_URL, {
                query: {
                    broadcaster_id: broadcaster.broadcaster_id
                },
                transports: ['websocket']
            })
            socket.onAny((eventName, value) => {
                //console.log(eventName, value)

                if (eventName === WEB_SOCKET_ID.MASS_ATTACK_SETTINGS_UPDATE) {
                    setBroadcaster(prevState => {
                        return {
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                mass_attack: value
                            }
                        }
                    })
                } else if (eventName === WEB_SOCKET_ID.ITEMS_SETTINGS_UPDATE) {
                    setBroadcaster(prevState => {
                        return {
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                items: value
                            }
                        }
                    })
                } else if (eventName === WEB_SOCKET_ID.UPDATE_MASS_ATTACK_VOLUME) {
                    playAudio(MassAttackAudio, value)
                    setBroadcaster(prevState => {
                        return {
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                advanced: {
                                    ...prevState.settings.advanced,
                                    mass_attack_voice_volume: value
                                }
                            }
                        }
                    })
                } else if (eventName === WEB_SOCKET_ID.TEST_BROWSER_SOURCE_SIZE) {
                    setShowBrowserSourceCorners(true)
                } else if (eventName === WEB_SOCKET_ID.UPDATE_LANGUAGE) {
                    i18n.locale = value
                    setBroadcaster(prevState => {
                        return {
                            ...prevState,
                            settings: {
                                ...prevState.settings,
                                language: value.language
                            }
                        }
                    })
                } else if (eventName === WEB_SOCKET_ID.TEST_ITEM) {
                    const attackItem = {
                        itemId: value.item_id,
                        displayName: value.display_name,
                        imageUrl: value.image_url,
                        audioUrl: value.audio_url,
                        text: value.text,
                        positionXPercent: 0,
                        positionYPercent: 0,
                        randomPosition: true,
                        volume: value.volume
                    }
                    spawnItem(attackItem)
                } else if (eventName === WEB_SOCKET_ID.ITEM_ATTACK) {
                    const attackItem = {
                        itemId: value.item_id,
                        displayName: value.display_name,
                        imageUrl: value.image_url,
                        audioUrl: value.audio_url,
                        text: value.text,
                        positionXPercent: value.position.xPercent,
                        positionYPercent: value.position.yPercent,
                        randomPosition: false,
                        volume: value.volume
                    }
                    spawnItem(attackItem)
                } else if (eventName === WEB_SOCKET_ID.START_MASS_ATTACK) {
                    setBroadcaster(prevState => {
                        const actual_bits = prevState.mass_attack.actual_bits
                        const bits_goal = prevState.settings.mass_attack.bits_goal
                        return {
                            ...prevState,
                            mass_attack: {
                                ...prevState.mass_attack,
                                start_date: new Date().getTime(), //in realtà ci andrebbe 'value.start_date' ma così si evitano vari problemi
                                actual_bits: actual_bits <= bits_goal ? 0 : actual_bits - bits_goal
                            }
                        }
                    })
                } else if (eventName === WEB_SOCKET_ID.UPDATE_ACTUAL_BITS) {
                    setBroadcaster(prevState => {
                        return {
                            ...prevState,
                            mass_attack: {
                                ...prevState.mass_attack,
                                actual_bits: value.actual_bits
                            }
                        }
                    })
                }
            })
        }

        return () => {
            if (socket) {
                console.log('Socket disconnected')
                socket.disconnect()
            }
        }
    }, [broadcaster.broadcaster_id, isLoaded, spawnItem, playAudio, pageInBackground])

    return (
        <>
            {/*<Unity unityProvider={unityProvider} style={{height: 1080, width: 1920, position: "absolute"}}/>*/}
            <canvas id="unity-canvas" width={1920} height={1080}
                    style={{ width: 1920, height: 1080, background: 'transparent' }}>
            </canvas>
            <>
                {
                    showBrowserSourceCorners &&
                    <>
                        <div className="browser-source-border-black browser-source-top-right-border-black"></div>
                        <div className="browser-source-border-black browser-source-top-left-border-black"></div>
                        <div className="browser-source-border-black browser-source-bottom-right-border-black"></div>
                        <div className="browser-source-border-black browser-source-bottom-left-border-black"></div>
                        <div className="browser-source-border-white browser-source-top-border-white"></div>
                        <div className="browser-source-border-white browser-source-left-border-white"></div>
                        <div className="browser-source-border-white browser-source-right-border-white"></div>
                        <div className="browser-source-border-white browser-source-bottom-border-white"></div>
                    </>
                }

                {
                    massAttackWaitingTimer > 0 &&
                    <div className="absolute-center">
                        <Stack alignItems="center">
                            <Typography variant="h3"
                                        className="cool-text-shadow mass-attack-starts-in-text">{i18n.t('mass_attack_starts_in').toUpperCase()}</Typography>
                            {massAttackWaitingTimer === 6 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time-invisible">5</Typography>}
                            {massAttackWaitingTimer === 5 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time">5</Typography>}
                            {massAttackWaitingTimer === 4 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time">4</Typography>}
                            {massAttackWaitingTimer === 3 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time">3</Typography>}
                            {massAttackWaitingTimer === 2 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time">2</Typography>}
                            {massAttackWaitingTimer === 1 && <Typography variant="h1"
                                                                         className="cool-text-shadow mass-attack-waiting-time">1</Typography>}
                        </Stack>
                    </div>
                }

                {
                    Boolean(!unityError && (!isLoaded ||
                        (broadcaster.settings.mass_attack.enabled && broadcaster.settings.mass_attack.bar.show))) &&
                    <div className="mass-attack-bar-container"
                         style={{
                             left: broadcaster.settings.mass_attack.bar.x + 'px',
                             top: broadcaster.settings.mass_attack.bar.y + 'px'
                         }}>
                        <div className="mass-attack-bar">
                            {
                                isLoaded ?
                                    <img className="mass-attack-bar-title" src={AppNameImage} height="35"
                                         alt="app-name"/>
                                    : <Typography variant="h6"
                                                  className="mass-attack-bar-loading cool-text-shadow">{i18n.t('loading')}</Typography>
                            }
                            <Stack direction="row" justifyContent="start" alignItems="center" spacing={-3}>
                                <div className="mass-attack-bar-logo" style={{ padding: 7 }}>
                                    <img src={Logo} height="84" width="84" alt="logo"/>
                                </div>
                                <div className="mass-attack-bar-progress">
                                    <BorderLinearProgress className={!isLoaded ? 'progress-bar-no-transition' : ''}
                                                          variant="determinate"
                                                          color={isMassAttack || !isLoaded ? 'primary' : 'secondary'}
                                                          value={
                                                              !isLoaded ? (loadingProgression * 100) :
                                                                  isMassAttack ? (massAttackTimer) :
                                                                      (broadcaster.mass_attack.actual_bits >= broadcaster.settings.mass_attack.bits_goal ? 100 :
                                                                          broadcaster.mass_attack.actual_bits * 100 / broadcaster.settings.mass_attack.bits_goal)
                                                          }/>
                                    <Typography variant="body1"
                                                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                className="absolute-center text-title-shadow">
                                        {!isLoaded ?
                                            (Math.round(loadingProgression * 100) + '%')
                                            :
                                            (broadcaster.mass_attack.actual_bits + '/' + broadcaster.settings.mass_attack.bits_goal + ' ' + i18n.t('bits'))
                                        }
                                    </Typography>
                                </div>
                            </Stack>
                        </div>
                    </div>
                }
            </>
        </>
    )
}